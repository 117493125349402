<ng-container *transloco="let t; scope: 'core'">
  <ng-container *ngIf="isLoggedIn$ | async">
    <div class="header-dropdown profile" id="header-dropdown-favourite">
      <div class="header-dropdown-label">
        <i class="icon-login"></i>
      </div>

      <div class="header-dropdown-content">
        <div class="header-dropdown-content-mobile-header">
          <i class="header-dropdown-content-mobile-header-icon icon-login"></i>
          {{ t("core.header-nav.profile-menu.my-profile") }}
          <span class="user-info" *ngIf="currentUser$ | async as user">
            ({{ user.lngCustomerID }}, {{ user.sFirstName }} {{ user.sLastName }})
          </span>
        </div>

        <div class="mini-cart">
          <ng-container *ngIf="currentUser$ | async as user">
            <div class="mini-cart-item default-font-sizes linked">
              <a [routerLink]="'/profile/my-profile' | localizeUrlPath" class="mini-cart-item-justify">
                <span>{{ t("core.header-nav.profile-menu.profile") }}</span>
              </a>
            </div>
            <div class="mini-cart-item default-font-sizes linked">
              <a [routerLink]="'/profile/carts' | localizeUrlPath" class="mini-cart-item-justify">
                <span>{{ t("core.header-nav.profile-menu.carts") }}</span>
              </a>
            </div>
            <div class="mini-cart-item default-font-sizes linked">
              <a [routerLink]="'/profile/favorites' | localizeUrlPath" class="mini-cart-item-justify">
                <span>{{ t("core.header-nav.profile-menu.favlists") }}</span>
              </a>
            </div>
            <div class="mini-cart-item default-font-sizes linked">
              <a [routerLink]="'/profile/shipping-addresses' | localizeUrlPath" class="mini-cart-item-justify">
                <span>{{ t("core.header-nav.profile-menu.addresses") }}</span>
              </a>
            </div>
            <div class="mini-cart-item default-font-sizes linked" *ngIf="accessId.SHOW_HISTORICAL_DATA | hasAccess | async">
              <a [routerLink]="'/profile/sales-offer' | localizeUrlPath" class="mini-cart-item-justify">
                <span>{{ t("core.header-nav.profile-menu.offers") }}</span>
              </a>
            </div>
            <div class="mini-cart-item default-font-sizes linked" *ngIf="accessId.SHOW_HISTORICAL_DATA | hasAccess | async">
              <a [routerLink]="'/profile/sales-order' | localizeUrlPath" class="mini-cart-item-justify">
                <span>{{ t("core.header-nav.profile-menu.orders") }}</span>
              </a>
            </div>
            <div class="mini-cart-item default-font-sizes linked">
              <a [routerLink]="'/profile/sales-credit-notes' | localizeUrlPath" class="mini-cart-item-justify">
                <span>{{ t("core.header-nav.profile-menu.credits-notes") }}</span>
              </a>
            </div>
            <div class="mini-cart-item default-font-sizes linked">
              <a [routerLink]="'/profile/backlog' | localizeUrlPath" class="mini-cart-item-justify">
                <span>{{ t("core.header-nav.profile-menu.backlog") }}</span>
              </a>
            </div>
            <div class="mini-cart-item default-font-sizes linked" *ngIf="accessId.SHOW_USER_MANAGEMENT | hasAccess | async">
              <a [routerLink]="'/profile/users' | localizeUrlPath" class="mini-cart-item-justify">
                <span>{{ t("core.header-nav.profile-menu.users") }}</span>
              </a>
            </div>
            <div class="mini-cart-item default-font-sizes linked">
              <a [routerLink]="'/profile/price-list-export' | localizeUrlPath" class="mini-cart-item-justify">
                <span>{{ t("core.header-nav.profile-menu.price-list-export") }}</span>
              </a>
            </div>
            <div class="mini-cart-item default-font-sizes linked">
              <a [routerLink]="'/profile/download' | localizeUrlPath" class="mini-cart-item-justify">
                <span>{{ t("core.header-nav.profile-menu.download") }}</span>
              </a>
            </div>
            <div class="mini-cart-item default-font-sizes linked" *ngIf="user.bP48CustomerHasFactoryAttestationFlat">
              <a [routerLink]="'/profile/factory-attestation' | localizeUrlPath" class="mini-cart-item-justify">
                <span>{{ t("core.header-nav.profile-menu.attestation") }}</span>
              </a>
            </div>
            <div class="mini-cart-item default-font-sizes linked" *ngIf="user.lngP48SalesRepID || user.sP48SalesRepForUser">
              <a [routerLink]="'/profile/sales-rep' | localizeUrlPath" class="mini-cart-item-justify">
                <span>{{ t("core.header-nav.profile-menu.sales-rep") }}</span>
              </a>
            </div>
          </ng-container>
          <div class="mini-cart-item default-font-sizes linked">
            <a class="mini-cart-item-justify" (click)="logout()">
              <span>{{ t("core.header-nav.profile-menu.logout") }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="header-nav-item-text">
      <ng-template #notLoggedin>
        <div>{{ t("core.header-nav.profile-menu.profile-title") }}</div>
      </ng-template>

      <div *ngIf="isLoggedIn$ | async; else notLoggedin">
        <i class="icon-arrow header-nav-item-text-icon header-nav-item-text-icon--first"></i>
        <span class="user-info listname-header-nav" *ngIf="currentUser$ | async as user"
          ><strong>{{ user.sFirstName }} {{ user.sLastName }}</strong>
        </span>
        <i class="icon-arrow header-nav-item-text-icon header-nav-item-text-icon--last"></i>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="(isLoggedIn$ | async) === false">
    <div class="header-dropdown">
      <div class="header-dropdown-label">
        <i class="icon-login"></i>
      </div>

      <div class="header-dropdown-content">
        <div class="mini-cart-item">
          <a (click)="openLoginDialog()" class="btn btn--wide text-centered margin--top-l default-font-sizes"
            ><i class="icon-login"></i> {{ t("core.header-nav.profile-menu.login") }}
          </a>
        </div>
        <div class="mini-cart-item mini-cart-item--footer">
          <a target="_blank" [href]="t('core.header-nav.profile-menu.register-url')"
            ><i class="icon-mail"></i> {{ t("core.header-nav.profile-menu.register") }}</a
          >
        </div>
      </div>
    </div>

    <div class="header-nav-item-text">
      <div>{{ t("core.header-nav.profile-menu.login-btn") }}</div>
    </div>
  </ng-container>
</ng-container>
