<ng-container *transloco="let t; scope: 'core'">
  <ng-container *ngIf="isLoggedIn$ | async">
    <div class="mobile-nav-header">
      <div *ngIf="currentUser$ | async as user" class="bold">
        <i class="icon-login icon-login-mobile"></i>{{ user.lngCustomerID }}, {{ user.sFirstName }} {{ user.sLastName }}
      </div>
      <i (click)="closeMobileNav.emit()" class="icon-close"></i>
    </div>

    <div class="mobile-content-fading">
      <ng-container *ngIf="currentUser$ | async as user">
        <div class="profile-menu-item">
          <a [routerLink]="'/profile/my-profile' | localizeUrlPath">
            <span>{{ t("core.header-nav.profile-menu.profile") }}</span>
          </a>
        </div>
        <div class="profile-menu-item">
          <a [routerLink]="'/profile/carts' | localizeUrlPath">
            <span>{{ t("core.header-nav.profile-menu.carts") }}</span>
          </a>
        </div>
        <div class="profile-menu-item">
          <a [routerLink]="'/profile/favorites' | localizeUrlPath">
            <span>{{ t("core.header-nav.profile-menu.favlists") }}</span>
          </a>
        </div>
        <div class="profile-menu-item">
          <a [routerLink]="'/profile/shipping-addresses' | localizeUrlPath">
            <span>{{ t("core.header-nav.profile-menu.addresses") }}</span>
          </a>
        </div>
        <div *ngIf="accessId.SHOW_HISTORICAL_DATA | hasAccess | async" class="profile-menu-item">
          <a [routerLink]="'/profile/sales-offer' | localizeUrlPath">
            <span>{{ t("core.header-nav.profile-menu.offers") }}</span>
          </a>
        </div>
        <div *ngIf="accessId.SHOW_HISTORICAL_DATA | hasAccess | async" class="profile-menu-item">
          <a [routerLink]="'/profile/sales-order' | localizeUrlPath">
            <span>{{ t("core.header-nav.profile-menu.orders") }}</span>
          </a>
        </div>
        <div class="profile-menu-item">
          <a [routerLink]="'/profile/sales-credit-notes' | localizeUrlPath">
            <span>{{ t("core.header-nav.profile-menu.credits-notes") }}</span>
          </a>
        </div>
        <div class="profile-menu-item">
          <a [routerLink]="'/profile/backlog' | localizeUrlPath">
            <span>{{ t("core.header-nav.profile-menu.backlog") }}</span>
          </a>
        </div>
        <div *ngIf="accessId.SHOW_USER_MANAGEMENT | hasAccess | async" class="profile-menu-item">
          <a [routerLink]="'/profile/users' | localizeUrlPath">
            <span>{{ t("core.header-nav.profile-menu.users") }}</span>
          </a>
        </div>
        <div class="profile-menu-item">
          <a [routerLink]="'/profile/price-list-export' | localizeUrlPath">
            <span>{{ t("core.header-nav.profile-menu.price-list-export") }}</span>
          </a>
        </div>
        <div class="profile-menu-item">
          <a [routerLink]="'/profile/download' | localizeUrlPath">
            <span>{{ t("core.header-nav.profile-menu.download") }}</span>
          </a>
        </div>
        <div *ngIf="user.bP48CustomerHasFactoryAttestationFlat" class="profile-menu-item">
          <a [routerLink]="'/profile/factory-attestation' | localizeUrlPath">
            <span>{{ t("core.header-nav.profile-menu.attestation") }}</span>
          </a>
        </div>
        <div *ngIf="user.lngP48SalesRepID || user.sP48SalesRepForUser" class="profile-menu-item">
          <a [routerLink]="'/profile/sales-rep' | localizeUrlPath">
            <span>{{ t("core.header-nav.profile-menu.sales-rep") }}</span>
          </a>
        </div>
      </ng-container>
      <div class="profile-menu-item">
        <a (click)="logout()">
          <span>{{ t("core.header-nav.profile-menu.logout") }}</span>
        </a>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="(isLoggedIn$ | async) === false">
    <div class="profile-menu-item">
      <a (click)="openLoginDialog()" class="btn btn--bright btn--wide text-centered default-font-sizes"
        ><i class="icon-login"></i> {{ t("core.header-nav.profile-menu.login") }}
      </a>
    </div>
    <div class="profile-menu-item register">
      <a [href]="t('core.header-nav.profile-menu.register')" target="_blank">
        <i class="icon-mail"></i> {{ t("core.header-nav.profile-menu.register") }}
      </a>
    </div>
  </ng-container>
</ng-container>
